import httpUtil from "@/utils/httpUtil";

/** 查询官网 */
export function selectOfficialWebsitePageList(params,type) {
    return httpUtil.post("/api/assPc/association/selectOfficialWebsitePageList", params, type)
}
/** 新增官网 */
export function addOfficialWebsite(params,type) {
    return httpUtil.post("/api/assPc/association/addOfficialWebsite", params, type)
}
/** 修改官网 */
export function upOfficialWebsite(params,type) {
    return httpUtil.post("/api/assPc/association/upOfficialWebsite", params, type)
}

/** 查询导航 */
export function selectNavigationPageList(params,type) {
    return httpUtil.post("/api/assPc/association/selectNavigationPageList", params, type)
}
/** 新增导航 */
export function addNavigation(params,type) {
    return httpUtil.post("/api/assPc/association/addNavigation", params, type)
}
/** 修改导航 */
export function upNavigation(params,type) {
    return httpUtil.post("/api/assPc/association/upNavigation", params, type)
}
/** 删除导航 */
export function delNavigation(params,type) {
    return httpUtil.post("/api/assPc/association/delNavigation", params, type)
}
/** 查询模板 */
export function selectTemplateSettingsPageList(params,type) {
    return httpUtil.post("/api/assPc/association/selectTemplateSettingsPageList", params, type)
}
/* 添加模板 */
export function addTemplateSettings(params,type) {
    return httpUtil.post("/api/assPc/association/addTemplateSettings", params, type)
}
/* 选择模板 */
export function upTemplateSettings(params,type) {
    return httpUtil.post("/api/assPc/association/upTemplateSettings", params, type)
}

export function queryOffWeb(params,type) {
    return httpUtil.get("/api/assPc/offWeb/queryOffWeb1", params, type)
}

//查询模板
export function queryAllTemplateSettings(params,type) {
    return httpUtil.post("/api/assPc/association/queryAllTemplateSettings", params, type)
}

//新增banner
export function addAssWebsiteBanner(params,type) {
    return httpUtil.post("/api/assPc/association/addAssWebsiteBanner", params, type)
}
//查询banner
export function queryPageAssWebsiteBanner(params,type) {
    return httpUtil.post("/api/assPc/association/queryPageAssWebsiteBanner", params, type)
}
//修改banner
export function updateAssWebsiteBanner(params,type) {
    return httpUtil.post("/api/assPc/association/updateAssWebsiteBanner", params, type)
}
//删除banner
export function delAssWebsiteBanner(params,type) {
    return httpUtil.post("/api/assPc/association/delAssWebsiteBanner", params, type)
}

//新增页面
export function addAssTempaltePage(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/addAssTempaltePage", params, type)
}
//查询页面
export function queryAssTempaltePageByPage(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/queryAssTempaltePageByPage", params, type)
}
//修改页面
export function updateAssTempaltePage(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/updateAssTempaltePage", params, type)
}
//刪除页面
export function delAssTempaltePage(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/delAssTempaltePage", params, type)
}
//默认导航
export function selectNavigationPageListByass(params,type) {
    return httpUtil.post("/api/assPc/association/selectNavigationPageListByass", params, type)
}
//查询链接
export function queryAssWebCustomData(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/queryAssWebCustomData", params, type)
}
//查询轮播
export function queryAssBannerForWebSite(params,type) {
    return httpUtil.post("/api/assPc/offWeb2/queryAssBannerForWebSite", params, type)
}
//查询关于我们
export function selectAboutUsList(params,type) {
    return httpUtil.post("/api/assPc/offWeb2/selectAboutUsList", params, type)
}
//查询动态信息
export function queryWorkByFatherId(params,type) {
    return httpUtil.post("/api/assPc/offWeb2/queryWorkByFatherId", params, type)
}
//查询视频信息
export function selectWebWorkVideoPageList2(params,type) {
    return httpUtil.post("/api/assPc/offWeb2/selectWebWorkVideoPageList2", params, type)
}
//查询资讯
export function queryDataInformationList(params,type) {
    return httpUtil.post("/api/assPc/offWeb2/queryDataInformationList", params, type)
}

//新建楼层
export function addAssWebsiteStorey(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/addAssWebsiteStorey", params, type)
}
//查询楼层
export function queryAssWebsiteStoreyByPage(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/queryAssWebsiteStoreyByPage", params, type)
}
//编辑楼层
export function updateAssWebsiteStorey(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/updateAssWebsiteStorey", params, type)
}
//删除楼层
export function delAssWebsiteStorey(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/delAssWebsiteStorey", params, type)
}
//楼层详情
export function queryAssWebsiteStoreyById(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/queryAssWebsiteStoreyById", params, type)
}
//查询页面
export function queryAssTempaltePage(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/queryAssTempaltePage", params, type)
}
//查询大类型
export function queryArticleTypeUse(params,type) {
    return httpUtil.post("/api/assPc/content/queryArticleTypeUse", params, type)
}
//查询文章
export function selectWorkPageList(params,type) {
    return httpUtil.post("/api/assPc/content/selectWorkPageList", params, type)
}
//商品
export function getGoodsInfoPC(params,type) {
    return httpUtil.post("/api/crmPc/companyGoods/getGoodsInfoPC", params, type)
}
//专题链接
export function queryCustomPageAll(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/queryCustomPageAll", params, type)
}


//自定义页面   --   自定义内容
//新增类型
export function addAssWebsiteStoreyOutType(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/addAssWebsiteStoreyOutType", params, type)
}
//查询
export function queryAssWebsiteStoreyOutTypeById(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/queryAssWebsiteStoreyOutTypeById", params, type)
}
//修改
export function updateAssWebsiteStoreyOutType(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/updateAssWebsiteStoreyOutType", params, type)
}
//查询小类
export function queryAssWebsiteStoreyOutType(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/queryAssWebsiteStoreyOutType", params, type)
}

//删除小类
export function delAssWebsiteStoreyOutType(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/delAssWebsiteStoreyOutType", params, type)
}

//上传数据
export function addAssWebsiteStoreyOutData(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/addAssWebsiteStoreyOutData", params, type)
}

//修改数据
export function updateAssWebsiteStoreyOutData(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/updateAssWebsiteStoreyOutData", params, type)
}

//查询数据
export function queryOutTypeData(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/queryOutTypeData", params, type)
}

//删除数据
export function delAssWebsiteStoreyOutData(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/delAssWebsiteStoreyOutData", params, type)
}

//选择数据到自定义页面
export function addBindOldDataByType(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/addBindOldDataByType", params, type)
}

//删除上传数据
export function cleanDataTypeByBigType(params,type) {
    return httpUtil.post("/api/assPc/AssWebsitePage/cleanDataTypeByBigType", params, type)
}














