<template>
  <div class="login_wrap">
    <div class="login-wrapper" v-if="ifPCAPP==2">
      <div class="login_img_wrap">
        <img src="../../assets/images/login_img.png" alt="" style="width: 100%;height: 100%">
      </div>
      <div
          class="bg-wrapper"
          :class="
        loginType == 3
          ? 'h320'
          : loginType == 2
          ? 'h450'
          : loginType == 3
          ? 'h380'
          : 'w600 h600'
      "
      >
        <!-- 密码登录 -->
        <div class="user-wrapper" v-if="loginType == 1">
          <!--        <div class="switch_img" @click="loginType = 2" :style="{display:showLogin}">-->
          <!--          <img-->
          <!--            src="../../assets/images/login/code_switch.png"-->
          <!--            class="w164 h52"-->
          <!--          />-->
          <!--        </div>-->
          <el-form :model="loginForm" ref="loginRef" style="width: 410px;height: 100%">
            <el-form-item prop="username">
              <p class="login_title">后台管理系统</p>
              <!--            <p class="login_title" v-if="nav==1">聚客台智能CRM</p>-->
            </el-form-item>
            <el-form-item style="margin-top: 20px" prop="username">
              <div style="display: flex;justify-content: space-evenly;width: 100%;border-bottom: 2px solid #f0f0f0">
                <p class="cA1 d-block fs8 fwbold pb5" :class="nav==0?'classA':'classB'" @click="navChoose(0)">企业端登录</p>
                <p class="cA1 d-block fs8 fwbold pb5" :class="nav==1?'classA':'classB'" @click="navChoose(1)">组织端登录</p>
              </div>
            </el-form-item>
            <el-form-item v-if="nav==0"
                          style="margin-top: 50px"
                          prop="username"
                          id="border-none"
            >
              <el-input
                  type="password"
                  style="position: fixed; bottom: -9999px"
              />
              <el-autocomplete
                  prefix-icon="el-icon-user"
                  class="inline-input"
                  type="text"
                  size="large"
                  auto-complete="new-accounts"
                  style="width: 100%;height: 46px"
                  v-model="loginForm.username"
                  popper-class="showAutocomplete"
                  :fetch-suggestions="getHistory"
                  placeholder="请输入手机号"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item style="margin-top: 25px;position: relative" v-if="nav==0" id="border-none">
              <img src="../../assets/images/company_icon.png" alt="" style="position:absolute;width: 14px;height: 14px;z-index: 99;top: 15px;left: 10px">
              <el-input
                  type="password"
                  style="position: fixed; bottom: -9999px"
              />
              <el-select
                  v-model="companyName"
                  size="large"
                  filterable
                  remote
                  @focus="queryCompany(loginForm.username)"
                  style="width: 100%"
                  placeholder="请选择公司"
                  @change="selectCompany"
              >
                <el-option
                    style="width: 100%"
                    v-for="(item,index) in oemLogin"
                    :key="index"
                    :label="item.companyName"
                    :value="item.companyId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="nav==0"
                          style="margin-top: 25px"
                          prop="password"
                          id="border-none"
            >
              <div class="flex-a-b-c">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-input
                    :type="
                  (readonly && loginForm.password) || loginForm.password
                    ? 'password'
                    : 'text'
                "
                    v-model="loginForm.password"
                    size="large"
                    style="width: 300px; border: none"
                    placeholder="请输入密码"
                    @focus="changAttr($event, 'focus')"
                    @blur="changAttr($event, 'blur')"
                    @keyup.enter.native="handleLogin"
                    @click.native="clickEvt"
                    ref="password"
                    prefix-icon="el-icon-lock"
                ></el-input>
                <el-button
                    plain
                    type="primary"
                    style="width: 100px;height: 46px"
                    size="large"
                    @click="clearInput(loginType = 4)"
                >忘记密码</el-button
                >
              </div>
            </el-form-item>
            <el-form-item style="margin-top: 40px" v-if="nav==0" align="center">
              <el-button
                  plain
                  type="primary"
                  style="width: 48%;height: 46px"
                  size="large"
                  @click="clearInput(loginType = 3)"
              >免费注册体验</el-button
              >
              <el-button
                  type="primary"
                  style="width: 48%;height: 46px"
                  size="large"
                  @click="handleLogin"
              >登录</el-button
              >
            </el-form-item>


            <el-form-item v-if="nav==1"
                          style="margin-top: 50px"
                          prop="username"
                          id="border-none"
            >
              <el-input
                  v-model="loginForm_zz.username"
                  size="large"
                  style="width: 100%; height: 46px"
                  placeholder="请输入您的账号"
                  ref="password"
                  prefix-icon="el-icon-user"
              ></el-input>
            </el-form-item>
            <el-form-item style="margin-top: 25px;position: relative" v-if="nav==1" id="border-none">
              <el-input
                  v-model="loginForm_zz.password"
                  size="large"
                  style="width: 100%; border: none"
                  placeholder="请输入密码"
                  ref="password"
                  prefix-icon="el-icon-lock"
                  @keyup.enter.native="handleLogin_zz"
                  show-password
              ></el-input>
            </el-form-item>
            <el-form-item v-if="nav==1"
                          style="margin-top: 25px"
                          prop="password"
                          id="border-none"
            >
              <div class="flex-a-b-c">
                <el-input
                    v-model="loginForm_zz.yzPass"
                    size="large"
                    style="width: 300px; border: none"
                    placeholder="请输入验证码"
                    ref="password"
                    prefix-icon="el-icon-lock"
                    @keyup.enter.native="handleLogin_zz"
                ></el-input>
                <img
                    :src="imgCode"
                    v-if="imgCode"
                    @click="showResetUserInfo"
                    class="w100 h40"
                />
              </div>
            </el-form-item>
            <el-form-item style="margin-top: 40px" v-if="nav==1" align="center">
              <el-button
                  type="primary"
                  style="width: 100%;height: 46px"
                  size="large"
                  @click="handleLogin_zz"
              >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <!-- 扫码登录 -->
        <div class="code-wrapper" v-if="loginType == 2">
          <div class="switch_img" @click="loginType = 1">
            <img
                src="../../assets/images/login/phone_switch.png"
                class="w164 h52"
            />
          </div>
          <el-form
              :model="loginForm"
              :rules="loginRules"
              align="center"
              ref="loginRef"
          >
            <iframe
                :src="codeUrl"
                class="w300 h400 mt20"
                scrolling="no"
                frameborder="0"
            >
            </iframe>
          </el-form>
        </div>

        <!-- 体验登录 -->
        <div class="experience-wrapper" v-if="loginType == 3">
          <div class="switch_img" @click="loginType = 1">
            <img
                src="../../assets/images/login/phone_switch.png"
                class="w164 h52"
            />
          </div>
          <el-form :model="registerForm" :rules="loginRules" ref="loginRef">
            <el-form-item style="margin-top: 20px" prop="username">
              <p class="cA1 d-block fs8 fwbold bbbule2 pb5" style="float: left">
                注册体验
              </p>
            </el-form-item>
            <el-form-item
                style="margin-top: 20px"
                prop="username"
                id="border-none"
            >
              <el-input
                  type="password"
                  style="position: fixed; bottom: -9999px"
              />
              <el-input
                  v-model="registerForm.username"
                  size="large"
                  style="width: 300px; border: none"
                  placeholder="请输入手机号"
                  prefix-icon="el-icon-mobile"
              ></el-input>
            </el-form-item>
            <el-form-item
                style="margin-top: 20px"
                prop="password"
                id="border-none"
            >
              <div class="flex-a-b-c">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-input
                    v-model="registerForm.password"
                    auto-complete="new-accounts"
                    size="large"
                    style="width: 160px; border: none"
                    placeholder="请输入验证码"
                    prefix-icon="el-icon-lock"
                ></el-input>
                <el-button
                    plain
                    type="primary"
                    :disabled="countDown !== 0 ? true : false"
                    size="large"
                    @click="getSendCode(registerForm.username)"
                >{{
                    countDown ? `重新发送(${countDown})` : "获取验证码"
                  }}</el-button
                >
              </div>
            </el-form-item>

            <el-form-item style="margin-top: 30px">
              <el-button
                  plain
                  type="primary"
                  style="width: 48%"
                  size="large"
                  @click="clearInput(loginType = 1)"
              >已有账号，去登录</el-button
              >
              <el-button
                  type="primary"
                  style="width: 48%"
                  size="large"
                  @click="handleLogin('test')"
              >立即体验</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <!-- 忘记密码 -->
        <div class="reset-wrapper" v-if="loginType == 4">
          <div class="switch_img" @click="loginType = 1">
            <img
                src="../../assets/images/login/phone_switch.png"
                class="w164 h52"
            />
          </div>
          <el-form :model="resetForm" ref="loginRef">
            <el-form-item style="margin-top: 20px">
              <p class="cA1 d-block fs8 fwbold bbbule2 pb5" style="float: left">
                忘记密码
              </p>
            </el-form-item>

            <el-form-item style="margin-top: 20px">
              <div class="flex-a-c">
                <div
                    class="flex-a-c"
                    v-for="(item, index) in resetState"
                    :key="index"
                >
                  <div
                      class="w24 h24 br50p be6eb flex-c-c"
                      :class="
                    resetType == item.type
                      ? 'bgbule cfff'
                      : resetType > item.type
                      ? 'cA2 bgfff'
                      : ''
                  "
                      v-if="resetType <= item.type"
                  >
                    {{ index + 1 }}
                  </div>
                  <i class="el-icon-circle-check fs12 cblue" v-else></i>
                  <p
                      class="fs7 ml10 cA2"
                      :class="resetType == item.type ? 'cA1 fwbold' : ''"
                  >
                    {{ item.title }}
                  </p>
                  <p class="mr10 ml10" v-if="index !== 2">-</p>
                </div>
              </div>
            </el-form-item>

            <el-form-item
                style="margin-top: 20px"
                id="border-none"
                v-if="resetType == 1"
            >
              <div class="mb10">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-input
                    v-model="resetForm.username"
                    auto-complete="new-accounts"
                    size="large"
                    style="border: none"
                    placeholder="请输入手机号"
                    prefix-icon="el-icon-mobile"
                ></el-input>
              </div>
              <div class="mb10">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-select
                    v-model="companyName"
                    auto-complete="new-accounts"
                    size="large"
                    filterable
                    remote
                    @focus="queryCompany(resetForm.username)"
                    style="width: 100%"
                    placeholder="请选择公司"
                    @change="selectCompany"
                >
                  <el-option
                      style="width: 300px"
                      v-for="(item,index) in oemLogin"
                      :key="index"
                      :label="item.companyName"
                      :value="item.companyId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="flex-a-b-c">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-input
                    v-model="resetForm.imgCode"
                    auto-complete="new-accounts"
                    size="large"
                    style="border: none; margin-right: 10px"
                    placeholder="图形验证码"
                    prefix-icon="el-icon-lock"
                ></el-input>
                <img
                    :src="imgCode"
                    v-if="imgCode"
                    @click="showResetUserInfo"
                    class="w100 h40"
                />
                <div class="bgf7fa w100 h40" v-else></div>
                <!-- <el-button plain type="primary" style="width: 80px" size="large" @click="getSendCode">验证码</el-button> -->
              </div>
              <div class="flex-a-b-c mt10">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-input
                    v-model="resetForm.messageCode"
                    auto-complete="new-accounts"
                    size="large"
                    style="border: none; margin-right: 10px"
                    placeholder="短信验证码"
                    prefix-icon="el-icon-lock"
                ></el-input>
                <el-button
                    plain
                    type="primary"
                    :disabled="resetCountDown !== 0 ? true : false"
                    size="large"
                    @click="getResetSendCode(resetForm.username)"
                >{{
                    resetCountDown ? `重新发送(${resetCountDown})` : "获取验证码"
                  }}</el-button
                >
              </div>
            </el-form-item>
            <el-form-item
                style="margin-top: 20px"
                id="border-none"
                v-if="resetType == 2"
            >
              <div class="flex-a-b-c mb20">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-input
                    :type="
                  (readonly && resetPwdForm.password) || resetPwdForm.password
                    ? 'password'
                    : 'text'
                "
                    v-model="resetPwdForm.password"
                    auto-complete="new-accounts"
                    size="large"
                    style="border: none"
                    placeholder="请输入新密码"
                    prefix-icon="el-icon-mobile"
                    @focus="changAttr($event, 'focus')"
                    @blur="changAttr($event, 'blur')"
                    @click.native="clickEvt"
                    ref="password"
                ></el-input>
              </div>
              <div class="flex-a-b-c">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-input
                    :type="
                  (readonly && resetPwdForm.secondPassword) || resetPwdForm.secondPassword
                    ? 'password'
                    : 'text'
                "
                    v-model="resetPwdForm.secondPassword"
                    auto-complete="new-accounts"
                    size="large"
                    style="border: none"
                    placeholder="请重复新密码"
                    prefix-icon="el-icon-lock"
                    @focus="changAttr($event, 'focus')"
                    @blur="changAttr($event, 'blur')"
                    @click.native="clickEvt"
                ></el-input>
              </div>
            </el-form-item>

            <el-form-item
                style="margin-top: 20px"
                id="border-none"
                v-if="resetType == 3"
            >
              <div class="flex-c-c h80 flex-column">
                <i class="el-icon-circle-check fs24 cblue"></i>
                <p class="cA1 fs14">设置成功</p>
              </div>
            </el-form-item>
            <el-form-item style="margin-top: 30px" v-if="resetType == 1">
              <el-button
                  type="primary"
                  style="width: 100%"
                  size="large"
                  @click="checkNumber"
              >下一步</el-button
              >
              <div class="flex-a-b-c mt10 cblue">
                <p
                    @click="clearInput(loginType = 3,resetType = 1)"
                    class="hover_pointer"
                >
                  免费注册体验
                </p>
                <p
                    @click="clearInput(loginType = 1, resetType = 1)"
                    class="hover_pointer"
                >
                  返回登录>
                </p>
              </div>
            </el-form-item>
            <el-form-item style="margin-top: 30px" v-else-if="resetType == 2">
              <el-button
                  type="primary"
                  style="width: 100%"
                  size="large"
                  @click="changePassword"
              >下一步</el-button
              >
              <div class="flex-a-b-c mt10 cblue">
                <p
                    @click="clearInput(loginType = 3,resetType = 1)"
                    class="hover_pointer"
                >
                  免费注册体验
                </p>
                <p
                    @click="clearInput(loginType = 1,resetType = 1)"
                    class="hover_pointer"
                >
                  返回登录>
                </p>
              </div>
            </el-form-item>
            <el-form-item style="margin-top: 30px" v-if="resetType == 3">
              <el-button
                  plain
                  type="primary"
                  style="width: 100%"
                  size="large"
                  @click="clearInput(loginType = 1,resetType = 1)
              "
              >立即登录</el-button
              >
              <div class="flex-c-c mt10 cblue">
                <p
                    @click="clearInput(loginType = 3,resetType = 1)"
                    class="hover_pointer"
                >
                  免费注册体验
                </p>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div style="position: absolute;bottom: 10px;color: #606266;font-weight: 400;font-size: 12px;display: flex">
        <img src="../../assets/images/company_logo.png" style="width: 14px;height: 14px;align-self: center" alt="">
        <div style="align-self: center;margin-left: 5px">聚客台提供技术支持（ 网址： <span @click="jump" class="http_info">www.juketai.com</span>  |  联系电话：400-9996885 ）</div>
      </div>
    </div>
    <div class="login-wrapper" v-if="ifPCAPP==1">
      <div
          class="bg-wrapper"
      >
        <!-- 密码登录 -->
        <div class="user-wrapper_app" v-if="loginType == 1">
          <el-form :model="loginForm" ref="loginRef" style="width: 85%">
            <el-form-item style="margin-top: 20px" prop="username">
              <div style="display: flex;justify-content: space-evenly;width: 100%;border-bottom: 2px solid #f0f0f0">
                <p class="d-block pb10" :class="nav==0?'classA_APP':'classB_APP'" @click="navChoose(0)">企业端登录</p>
                <p class="d-block pb10" :class="nav==1?'classA_APP':'classB_APP'" @click="navChoose(1)">组织端登录</p>
              </div>
            </el-form-item>
            <el-form-item v-if="nav==0"
                          style="margin-top: 20px"
                          prop="username"
                          id="border-none"
            >
              <el-input
                  type="password"
                  style="position: fixed; bottom: -9999px"
              />
              <el-autocomplete
                  prefix-icon="el-icon-user"
                  class="inline-input"
                  type="text"
                  size="large"
                  auto-complete="new-accounts"
                  style="width: 100%;height: 46px"
                  v-model="loginForm.username"
                  popper-class="showAutocomplete"
                  :fetch-suggestions="getHistory"
                  placeholder="请输入手机号"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item style="margin-top: 20px;position: relative" v-if="nav==0" id="border-none">
              <img src="../../assets/images/company_icon.png" alt="" style="position:absolute;width: 14px;height: 14px;z-index: 99;top: 17px;left: 10px">
              <el-input
                  type="password"
                  style="position: fixed; bottom: -9999px"
              />
              <el-select
                  v-model="companyName"
                  size="large"
                  filterable
                  remote
                  @focus="queryCompany(loginForm.username)"
                  style="width: 100%"
                  placeholder="请选择公司"
                  @change="selectCompany"
              >
                <el-option
                    style="width: 100%"
                    v-for="(item,index) in oemLogin"
                    :key="index"
                    :label="item.companyName"
                    :value="item.companyId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="nav==0"
                          style="margin-top: 20px"
                          prop="password"
                          id="border-none"
            >
              <div class="flex-a-b-c">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-input
                    type="password"
                    v-model="loginForm.password"
                    size="large"
                    style="width: 300px; border: none"
                    placeholder="请输入密码"
                    ref="password"
                    prefix-icon="el-icon-lock"
                ></el-input>

              </div>
            </el-form-item>
            <el-form-item v-if="nav==0"
                          style="text-align: right"
                          prop="password"
                          id="border-none"
            >
              <div  @click="clearInput(loginType = 4)" style="color: #51cbcd;">
                忘记密码?
              </div>
            </el-form-item>
            <el-form-item style="margin-top: 15px" v-if="nav==0" align="center">
              <el-button
                  type="primary"
                  style="width: 100%;height: 46px;border-radius: 48px"
                  size="large"
                  @click="handleLogin"
              >登录</el-button
              >
            </el-form-item>
            <el-form-item style="margin-top: 10px;margin-bottom: 30px" v-if="nav==0" align="center">
              <el-button
                  plain
                  type="primary"
                  style="width: 100%;height: 46px;border-radius: 48px;"
                  size="large"
                  @click="clearInput(loginType = 3)"
              >免费注册体验</el-button
              >
            </el-form-item>


            <el-form-item v-if="nav==1"
                          style="margin-top: 25px"
                          prop="username"
                          id="border-none"
            >
              <el-input
                  v-model="loginForm_zz.username"
                  size="large"
                  style="width: 100%; height: 46px"
                  placeholder="请输入您的账号"
                  ref="password"
                  prefix-icon="el-icon-user"
              ></el-input>
            </el-form-item>
            <el-form-item style="margin-top: 20px;position: relative" v-if="nav==1" id="border-none">
              <el-input
                  v-model="loginForm_zz.password"
                  size="large"
                  style="width: 100%; border: none"
                  placeholder="请输入密码"
                  ref="password"
                  prefix-icon="el-icon-lock"
                  @keyup.enter.native="handleLogin_zz"
                  show-password
              ></el-input>
            </el-form-item>
            <el-form-item v-if="nav==1"
                          style="margin-top: 20px"
                          prop="password"
                          id="border-none"
            >
              <div class="flex-a-b-c1">
                <el-input
                    v-model="loginForm_zz.yzPass"
                    size="large"
                    style="width: 300px; border: none"
                    placeholder="请输入验证码"
                    ref="password"
                    prefix-icon="el-icon-lock"
                    @keyup.enter.native="handleLogin_zz"
                ></el-input>
                <img
                    :src="imgCode"
                    v-if="imgCode"
                    @click="showResetUserInfo"
                    class="w80 h30"
                    style="margin-right: 10px"
                />
              </div>
            </el-form-item>
            <el-form-item style="margin-top: 20px" v-if="nav==1" align="center">
              <el-button
                  type="primary"
                  style="width: 100%;height: 48px;border-radius: 48px"
                  size="large"
                  @click="handleLogin_zz"
              >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <!-- 扫码登录 -->
        <div class="code-wrapper" v-if="loginType == 2">
          <div class="switch_img" @click="loginType = 1">
            <img
                src="../../assets/images/login/phone_switch.png"
                class="w164 h52"
            />
          </div>
          <el-form
              :model="loginForm"
              :rules="loginRules"
              align="center"
              ref="loginRef"
          >
            <iframe
                :src="codeUrl"
                class="w300 h400 mt20"
                scrolling="no"
                frameborder="0"
            >
            </iframe>
          </el-form>
        </div>

        <!-- 体验登录 -->
        <div class="experience-wrapper_app" v-if="loginType == 3">
<!--          <div class="switch_img" @click="loginType = 1">-->
<!--            <img-->
<!--                src="../../assets/images/login/phone_switch.png"-->
<!--                class="w164 h52"-->
<!--            />-->
<!--          </div>-->
          <el-form :model="registerForm" :rules="loginRules" ref="loginRef">
            <el-form-item style="margin-top: 20px" prop="username">
              <p class="cA1 d-block fs8 fwbold pb5" style="text-align: center;border-bottom: 2px solid #d5d7d7;">
                <span style="width: 100px;border-bottom: 2px solid #51CDCB;padding-bottom: 13px;">注册体验</span>
              </p>
            </el-form-item>
            <el-form-item
                style="margin-top: 20px"
                prop="username"
                id="border-none"
            >
              <el-input
                  type="password"
                  style="position: fixed; bottom: -9999px"
              />
              <el-input
                  v-model="registerForm.username"
                  size="large"
                  style="width: 300px; border: none"
                  placeholder="请输入手机号"
                  prefix-icon="el-icon-mobile"
              ></el-input>
            </el-form-item>
            <el-form-item
                style="margin-top: 20px"
                prop="password"
                id="border-none"
            >
              <div class="flex-a-b-c">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-input
                    v-model="registerForm.password"
                    auto-complete="new-accounts"
                    size="large"
                    style="width: 160px; border: none"
                    placeholder="请输入验证码"
                    prefix-icon="el-icon-lock"
                ></el-input>
                <el-button
                    style="height: 48px;border-radius: 48px"
                    plain
                    type="primary"
                    :disabled="countDown !== 0 ? true : false"
                    size="large"
                    @click="getSendCode(registerForm.username)"
                >{{
                    countDown ? `重新发送(${countDown})` : "获取验证码"
                  }}</el-button
                >
              </div>
            </el-form-item>

            <el-form-item style="margin-top: 20px">
              <el-button
                  type="primary"
                  style="width: 100%;border-radius: 48px;height: 48px"
                  size="large"
                  @click="handleLogin('test')"
              >立即体验</el-button
              >
            </el-form-item>
            <el-form-item style="margin-top: 20px">
              <el-button
                  plain
                  type="primary"
                  style="width: 100%;border-radius: 48px;height: 48px"
                  size="large"
                  @click="clearInput(loginType = 1)"
              >已有账号，去登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <!-- 忘记密码 -->
        <div class="reset-wrapper_APP" v-if="loginType == 4">
<!--          <div class="switch_img" @click="loginType = 1">-->
<!--            <img-->
<!--                src="../../assets/images/login/phone_switch.png"-->
<!--                class="w164 h52"-->
<!--            />-->
<!--          </div>-->
          <el-form :model="resetForm" ref="loginRef" style="width: 90%">
            <el-form-item style="margin-top: 20px;">
              <p class="cA1 d-block fs8 fwbold pb5" style="text-align: center;border-bottom: 2px solid #d5d7d7;">
                <span style="width: 100px;border-bottom: 2px solid #51CDCB;padding-bottom: 13px;">忘记密码</span>
              </p>
            </el-form-item>
            <el-form-item style="margin-top: 20px">
              <div class="flex-a-c">
                <div
                    class="flex-a-c"
                    v-for="(item, index) in resetState"
                    :key="index"
                >
                  <div
                      class="w24 h24 br50p be6eb flex-c-c"
                      :class="
                    resetType == item.type
                      ? 'bgbule cfff'
                      : resetType > item.type
                      ? 'cA2 bgfff'
                      : ''
                  "
                      v-if="resetType <= item.type"
                  >
                    {{ index + 1 }}
                  </div>
                  <i class="el-icon-circle-check fs12 cblue" v-else></i>
                  <p
                      style="white-space: nowrap"
                      class="fs6 ml8 cA2"
                      :class="resetType == item.type ? 'cA1 fwbold' : ''"
                  >
                    {{ item.title }}
                  </p>
                  <p class="mr10 ml8" v-if="index !== 2">-</p>
                </div>
              </div>
            </el-form-item>
            <el-form-item
                style="margin-top: 20px"
                id="border-none"
                v-if="resetType == 1"
            >
              <div class="mb10">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-input
                    v-model="resetForm.username"
                    auto-complete="new-accounts"
                    size="large"
                    style="border: none"
                    placeholder="请输入手机号"
                    prefix-icon="el-icon-mobile"
                ></el-input>
              </div>
              <div class="mb10">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-select
                    v-model="companyName"
                    auto-complete="new-accounts"
                    size="large"
                    filterable
                    remote
                    @focus="queryCompany(resetForm.username)"
                    style="width: 100%"
                    placeholder="请选择公司"
                    @change="selectCompany"
                >
                  <el-option
                      style="width: 300px"
                      v-for="(item,index) in oemLogin"
                      :key="index"
                      :label="item.companyName"
                      :value="item.companyId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="flex-a-b-c1">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-input
                    v-model="resetForm.imgCode"
                    auto-complete="new-accounts"
                    size="large"
                    style="border: none; margin-right: 10px"
                    placeholder="图形验证码"
                    prefix-icon="el-icon-lock"
                ></el-input>
                <img
                    :src="imgCode"
                    v-if="imgCode"
                    @click="showResetUserInfo"
                    class="w80 h30"
                    style="margin-right: 10px"
                />
                <div class="bgf7fa w100 h40" v-else></div>
                <!-- <el-button plain type="primary" style="width: 80px" size="large" @click="getSendCode">验证码</el-button> -->
              </div>
              <div class="flex-a-b-c mt10">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-input
                    v-model="resetForm.messageCode"
                    auto-complete="new-accounts"
                    size="large"
                    style="border: none; margin-right: 10px;"
                    placeholder="短信验证码"
                    prefix-icon="el-icon-lock"
                ></el-input>
                <el-button
                    style="border-radius: 48px;height: 48px"
                    plain
                    type="primary"
                    :disabled="resetCountDown !== 0 ? true : false"
                    size="large"
                    @click="getResetSendCode(resetForm.username)"
                >{{
                    resetCountDown ? `重新发送(${resetCountDown})` : "获取验证码"
                  }}</el-button
                >
              </div>
            </el-form-item>
            <el-form-item
                style="margin-top: 20px"
                id="border-none"
                v-if="resetType == 2"
            >
              <div class="flex-a-b-c mb20">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-input
                    type="password"
                    v-model="resetPwdForm.password"
                    auto-complete="new-accounts"
                    size="large"
                    style="border: none"
                    placeholder="请输入新密码"
                    prefix-icon="el-icon-mobile"
                    ref="password"
                ></el-input>
              </div>
              <div class="flex-a-b-c">
                <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
                />
                <el-input
                    type="password"
                    v-model="resetPwdForm.secondPassword"
                    auto-complete="new-accounts"
                    size="large"
                    style="border: none"
                    placeholder="请重复新密码"
                    prefix-icon="el-icon-lock"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item
                style="margin-top: 20px"
                id="border-none"
                v-if="resetType == 3"
            >
              <div class="flex-c-c h80 flex-column">
                <i class="el-icon-circle-check fs24 cblue"></i>
                <p class="cA1 fs14" style="margin-top: 10px;">设置成功</p>
              </div>
            </el-form-item>
<!--            -->
            <el-form-item style="margin-top: 20px" v-if="resetType == 1">
              <el-button
                  type="primary"
                  style="width: 100%;border-radius: 48px;height: 48px"
                  size="large"
                  @click="checkNumber"
              >下一步</el-button
              >
              <div class="flex-a-b-c mt10 cblue">
                <p
                    @click="clearInput(loginType = 3,resetType = 1)"
                    class="hover_pointer"
                >
                  免费注册体验
                </p>
                <p
                    @click="clearInput(loginType = 1, resetType = 1)"
                    class="hover_pointer"
                >
                  返回登录>
                </p>
              </div>
            </el-form-item>
<!--           -->
            <el-form-item style="margin-top: 30px"  v-else-if="resetType == 2">
              <el-button
                  type="primary"
                  style="width: 100%;border-radius: 48px;height: 48px"
                  size="large"
                  @click="changePassword"
              >下一步</el-button
              >
              <div class="flex-a-b-c mt10 cblue">
                <p
                    @click="clearInput(loginType = 3,resetType = 1)"
                    class="hover_pointer"
                >
                  免费注册体验
                </p>
                <p
                    @click="clearInput(loginType = 1,resetType = 1)"
                    class="hover_pointer"
                >
                  返回登录>
                </p>
              </div>
            </el-form-item>
<!--            -->
            <el-form-item style="margin-top: 30px" v-if="resetType == 3">
              <el-button
                  plain
                  type="primary"
                  style="width: 100%;border-radius: 48px;height: 48px"
                  size="large"
                  @click="clearInput(loginType = 1,resetType = 1)
              "
              >立即登录</el-button
              >
              <div class="flex-c-c mt10 cblue">
                <p
                    @click="clearInput(loginType = 3,resetType = 1)"
                    class="hover_pointer"
                >
                  免费注册体验
                </p>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div style="position: absolute;bottom: 10px;color: #606266;font-weight: 400;font-size: 12px;display: flex;flex-direction: column;margin-top: 20px">
        <img src="../../assets/images/company_logo_app.png" style="width: 32px;height: 32px;align-self: center" alt="">
        <div style="text-align: center;margin: 10px 0;color: #a2a2a2">
          聚客台提供技术支持
        </div>
        <div style="align-self: center;color: #a2a2a2">网址： <span @click="jump" class="http_info" style=" border-bottom: 1px solid #dedddd;">www.juketai.com</span>  |  联系电话：400-9996885</div>
      </div>
    </div>
  </div>
</template>

<script>
import {queryOffWeb} from '@/api/website'
import api from "@/api/login";
import { getDataTimeSec } from "@/utils/index";
import {
  queryOemDomain
} from "@/api/oemConfig.js";

export default {
  name: "login",
  data() {
    return {
      ifPCAPP:2,
      nav:0,
      loginForm_zz: {
        username: "",
        password: "",
        yzPass:"",
      },
      loginForm: {
        username: "",
        password: "",
      },
      showLogin: "none" ,
      registerForm: {
        username: "",
        password: "",
        companyName: "",
        companyId: "",
      },
      resetForm: {
        username: "",
        imgCode: "",
        messageCode: "",
      },
      resetPwdForm: {
        password: "",
        secondPassword: "",
      },
      companyName: "", // 公司名
      oemLogin: [],
      loginType: 1, //登录方式 1为账号密码登录  2为 扫码登录  3为 体验账号登录 4为 忘记密码
      companyId: "",
      codeUrl: "",
      resetType: 1, //修改密码方式 1为手机号验证  2为 设置新密码  3为 成功
      imgCode: "",
      signature: "", //认证签名
      resetState: [
        {
          title: "安全验证",
          type: 1,
        },
        {
          title: "设置新密码",
          type: 2,
        },
        {
          title: "设置成功",
          type: 3,
        },
      ],
      countDown: 0,
      resetCountDown: 0,
      readonly: true,
    };
  },
  async created() {
    console.log("domain:",document.domain);
    this.getCodeUrl();
    this.getUserInfo();
    this.showResetUserInfo();
    if(document.domain != "localhost" && document.domain != "crm.juketai.com" && document.domain != "192.168.1.31"){
      this.showLogin = "none";
      const oemConfig = await queryOemDomain({oemDomain:document.domain});
      if(!oemConfig){
        this.$message.error('oem配置错误,请联系售后');
        return;
      }
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';

      if(oemConfig){
        link.href = oemConfig.data.iconLogo;
        document.getElementsByTagName('head')[0].appendChild(link);
        //获取网页标题
        document.title = oemConfig.data.oemName;
      }else{
        // link.href = "https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/one-stand/icon/favicon.ico";
        // document.getElementsByTagName('head')[0].appendChild(link);
        //获取网页标题
        document.title = "CRM后台";
      }
    }else{
      this.showLogin = "disabled";
    }

  },
  mounted() {
    let clientWidth = document.body.clientWidth
    if (clientWidth <= 768) {
      this.ifPCAPP = 1
    }else {
      this.ifPCAPP = 2
    }
  },

  methods: {
    jump(){
      window.open('https://www.juketai.com',"_blank");
    },
    //密码的历史记录
    getHistory(queryString, cb) {
      let $cname = document.querySelector(".showAutocomplete");
      let history = JSON.parse(localStorage.getItem("password")),
        arr = [];
        if(history){
          history.forEach((v, i) => {
            arr.push({
              value: v.value.username,
              password: v.value.password,
            });
          });
        }
      arr.length == 0 ? ($cname.style.display = "none") : ''
      cb(arr);
    },
    // 查询公司
    queryCompany(val) {
      if (!/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(val)) {
        this.loginForm.username = "";
        this.resetForm.username = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      api.fetchCompany({ phone: val }, { showLoading: false }).then((res) => {
        let oemLogin = res.data;
        for (let i in oemLogin) {
          if ( oemLogin[i].associationName ){
            oemLogin[i].companyName = oemLogin[i].companyName + ' / ' + oemLogin[i].associationName
          }
        }
        this.oemLogin = oemLogin
      });
    },
    getSendCode(phone) {
      if (this.countDown) return;
      if (!/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(phone)) {
        phone = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      api.fetchCompany({ phone: phone }, { showLoading: false }).then((res) => {
        this.countDown = 60;
        this.timer = setInterval(() => {
          this.countDown--;
          if (this.countDown === 0) {
            clearInterval(this.timer);
          }
        }, 1 * 1000);
        console.log(res);
        this.registerForm.companyId = res.data[0].companyId;
        this.registerForm.companyName = res.data[0].companyName;
      });
      api
        .fetchVeriCode({ phone: phone, type: 1 }, { showLoading: false })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },

    getResetSendCode(phone) {
      if (this.resetCountDown) return;
      if (!/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(phone)) {
        phone = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      api.fetchCompany({ phone: phone }, { showLoading: false }).then((res) => {
        this.resetCountDown = 60;
        this.timer = setInterval(() => {
          this.resetCountDown--;
          if (this.resetCountDown === 0) {
            clearInterval(this.timer);
          }
        }, 1 * 1000);
        console.log(res);
        this.registerForm.companyId = res.data[0].companyId;
        this.registerForm.companyName = res.data[0].companyName;
      });
      api
        .fetchVeriCode({ phone: phone, type: 1 }, { showLoading: false })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },

    getUserInfo() {
      // let data = {
      //   code:"091LE0100dV9sL1JAp100a9sAR3LE01i",
      //   state:'one-stand'
      // }
      // api.pcLoginCallBack(data)
      // .then(res=>{
      //   console.log(res)
      // })
    },
    //获取验证码
    getCodeUrl() {
      console.log('00000')
      api
        .fetchCodeUrl()
        .then((res) => {
          this.codeUrl = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择公司
    selectCompany(val) {
      this.companyId = val;
    },
    //获取图形验证码
    showResetUserInfo() {
      let data = {
        phone: this.resetForm.username || "",
      };
      api
        .getVerifiCode(data)
        .then((res) => {
          console.log();
          this.imgCode = "data:image/png;base64," + res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkNumber() {
      console.log('0000')
      if(this.oemLogin[0].companyId == 1){
          this.$message.error('此手机号未注册，请输入已注册手机号或点击免费注册体验可免费体验查看');
          return
        }
      // || !this.resetForm.messageCode
      if (
        !this.resetForm.username ||
        !this.resetForm.imgCode || !this.resetForm.messageCode
      ) {
        this.$message({
          message: "手机号或验证码为空",
          type: "error",
        });
        return;
      }
      if (!/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(this.resetForm.username)) {
        this.loginForm.username = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      let data = {
        imgCode: this.resetForm.imgCode,
        smsCode: this.resetForm.messageCode,
        phone: this.resetForm.username,
      };
      api
        .forgetPwd(data)
        .then((res) => {
          this.showResetUserInfo();
          this.signature = res.data;
          this.resetType = 2;
        })
        .catch((err) => {
          if (err.code == 201) {
            this.$message({
              title: "警告",
              message: err.message,
              type: "warning",
            });
          }
        });
    },
    changePassword() {
      if (
        this.resetPwdForm.password.length < 6 ||
        this.resetPwdForm.secondPassword.length < 6
      ) {
        this.$message({
          message: "密码长度不得小于六位",
          type: "warning",
        });
        return;
      }
      if (this.resetPwdForm.password !== this.resetPwdForm.secondPassword) {
        this.$message({
          message: "两次输入的密码不一，请重新输入",
          type: "warning",
        });
        return;
      }
      let data = {
        signature: this.signature,
        password: this.resetPwdForm.password,
        companyId: this.companyId,
      };
      api
        .updatePwd(data)
        .then((res) => {
          console.log(res);
          this.resetType = 3;
        })
        .catch((err) => {
          if (err.code == 201) {
            this.$message({
              message: err.message,
              type: "warning",
            });
            return;
          }
        });
    },
    // 清除登录数据
    clearInput(){
      this.loginForm = {
        username: "",
        password: "",
      }
      this.registerForm = {
        username: "",
        password: "",
        companyName: "",
        companyId: "",
      }
      this.resetForm = {
        username: "",
        imgCode: "",
        messageCode: "",
      }
      this.resetPwdForm= {
        password: "",
        secondPassword: "",
      }
      this.companyName = ""
    },
    // 登录请求
    handleLogin(type) {
      //登录时间
      let date = new Date();
      date = getDataTimeSec(date);
      let value = "";
      if (type !== "test") {
        //非体验用户登录
        value = {
          username: this.loginForm.username,
          phone: this.loginForm.username,
          password: this.loginForm.password,
          companyName: this.companyName,
          companyId: this.companyId,
        };
      } else {
        //体验用户登录
        value = {
          username: this.registerForm.username,
          phone: this.registerForm.username,
          // password: this.loginForm.password,
          smsCode: this.registerForm.password,
          companyName: this.registerForm.companyName,
          companyId: this.registerForm.companyId,
        };
      }

      let than = this,
        passwordArr = [], //
        text = "password";

      localStorage.removeItem("password");
      passwordArr.push({ value });

      if (JSON.parse(localStorage.getItem(text))) {
        // 判断是否已有xxx查询记录的localStorage
        if (localStorage.getItem(text).indexOf(value.username) > -1) {
          // 判断是否已有此条查询记录，若已存在，则修改顺序
          let userArr = JSON.parse(localStorage.getItem(text));
          console.log(userArr);
          userArr.forEach((v, k) => {
            console.log(v);
            if (v.value.username == value.username) {
              //将重复的username顺序提前
              let currentUser = userArr.splice(k, 1);
              userArr.unshift(currentUser[0]);
              localStorage.setItem(text, JSON.stringify(userArr));
            }
          });
        } else if (JSON.parse(localStorage.getItem(text)).length >= 5) {
          //记录大于五条时删除最后一条添加新的记录
          let arr = JSON.parse(localStorage.getItem(text));
          arr.pop();
          arr.unshift(passwordArr[0]);
          localStorage.setItem(text, JSON.stringify(arr));
        } else {
          //没有记录且不大于五条记录
          localStorage.setItem(
            text,
            JSON.stringify(
              passwordArr.concat(JSON.parse(localStorage.getItem(text)))
            )
          );
        }
      } else {
        // 首次创建
        localStorage.removeItem("password");
        localStorage.setItem(text, JSON.stringify(passwordArr));
      }
      if (type !== "test") {
        //手机密码登录
        if(value.companyId == 1){
          this.$message.error('此手机号未注册，请输入已注册手机号或点击免费注册体验可免费体验查看');
          return
        }
        if(!value.username  || !value.companyName || !value.password){
          this.$message.error('账号信息不完整，请填写完整');
          return
        }
        api
          .fetchLoginByPhone(value)
          .then((res) => {
            if (res.code === 200) {
              //存储登录用户信息
              this.$store.state.loginRoot.loginDate = res.data.lastLoginTime
              this.$store.state.loginRoot.userInfo = res.data;
              localStorage.setItem("info", JSON.stringify(res.data));
              localStorage.setItem("loginDate", res.data.lastLoginTime);
              //存储token
              localStorage.setItem("token", res.data.token);
              //存储associationId
              if (res.data.assList){
                localStorage.setItem("associationId", res.data.assList[0].associationId);
                //存储companyKey
                localStorage.setItem("companyKey", res.data.assList[0].companyKey);
                localStorage.setItem("logo", res.data.assList[0].logo);
                localStorage.setItem("associationName", res.data.assList[0].associationName);
                localStorage.setItem("public2Code", res.data.assList[0].public2Code);
              }
              //存储userId
              localStorage.setItem("userId", res.data.userId);
              //存储userName
              localStorage.setItem("userName", res.data.userName);
              //存储companyType
              localStorage.setItem("companyType", res.data.companyType);
              //companyLogo
              localStorage.setItem("companyLogo", res.data.companyLogo);

              //存储companyId
              localStorage.setItem("companyId", res.data.companyId);

              //存储companyId
              localStorage.setItem("roleId", res.data.roleId);
              localStorage.setItem("companyName", res.data.companyName);
              localStorage.setItem("tabrFlag", 'QY');
              this.$router.push("/dashboard");
            } else {
              console.log('0000000')
              this.$message.error(res.message);
              return;
            }
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      } else {

        //体验账号登录
        // if(!value.username || !value.smsCode){
        //   this.$message.error('账号信息不完整，请填写完整');
        //   return
        // }
        console.log(value)
        api.fetchLogin(value)
          .then((res) => {
            if (res.code === 200) {
              //存储登录用户信息
              this.$store.state.loginRoot.loginDate = date;
              this.$store.state.loginRoot.userInfo = res.data;
              localStorage.setItem("info", JSON.stringify(res.data));
              localStorage.setItem("loginDate", res.data.lastLoginTime)
              //存储token
              localStorage.setItem("token", res.data.token);
              this.$router.push("/dashboard");
            } else {
              this.$message.error(res.message);
              return;
            }
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      }
    },
    //组织端登录
    handleLogin_zz() {
      console.log('888')
        //非体验用户登录
        let value = {
          account: this.loginForm_zz.username,
          password: this.loginForm_zz.password,
          imgText: this.loginForm_zz.yzPass,
        };
      if(!value.account  || !value.password || !value.imgText){
        this.$message.error('账号信息不完整，请填写完整');
        return
      }
      api.crmPcTOLoginByAccNumber(value)
          .then((res) => {
            if (res.code === 200) {
              //存储登录用户信息
              this.$store.state.loginRoot.loginDate = res.data.lastLoginTime
              this.$store.state.loginRoot.userInfo = res.data;
              localStorage.setItem("info", JSON.stringify(res.data));
              localStorage.setItem("loginDate", res.data.lastLoginTime);
              //存储token
              localStorage.setItem("token", res.data.token);
              //存储associationId
              if (res.data.assList){
                localStorage.setItem("associationId", res.data.assList[0].associationId);
                //存储companyKey
                localStorage.setItem("companyKey", res.data.assList[0].companyKey);
                localStorage.setItem("logo", res.data.assList[0].logo);
                localStorage.setItem("associationName", res.data.assList[0].associationName);
                localStorage.setItem("public2Code", res.data.assList[0].public2Code);
                localStorage.setItem("headImg", res.data.assList[0].logo);
              } else {
                localStorage.setItem("headImg", res.data.headImg);
              }
              //存储userId
              // localStorage.setItem("userId", res.data.userId);
              //存储userName
              localStorage.setItem("userName", res.data.userName);
              //存储companyType
              localStorage.setItem("companyType", res.data.companyType);

              //存储companyId
              localStorage.setItem("companyId", res.data.companyId);

              //存储companyId
              localStorage.setItem("roleId", res.data.roleId);

              //存储name
              localStorage.setItem("name", res.data.name);

              //存储assIsMain
              localStorage.setItem("assIsMain", res.data.assIsMain);

              localStorage.setItem("tabrFlag", 'ZZ');

              this.$router.push("/dashboard");
            } else {
              this.$message.error(res.message);
              return;
            }
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
    },

    changAttr(e, type) {
      if (type === "focus") {
        if (e) {
          e.stopPropagation();
          e.preventDefault();
        }
        setTimeout(() => {
          this.readonly = false;
        }, 100);
      } else {
        if (e) {
          e.stopPropagation();
        }
        this.readonly = true;
      }
    },
    clickEvt() {
      if (this.$refs.password) {
        this.$refs.password.$refs.input.onmousedown = (evt) => {
          if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
          }
          if (this.loginForm.password === "" || this.readonly) {
            this.$refs.password.$refs.input.blur();
            setTimeout(() => {
              this.$refs.password.$refs.input.focus();
            }, 0);
          }
          return false;
        };
      }
    },
    navChoose(num){
      this.nav = num
    }
  },
};
</script>

<style lang="scss" scoped>
//app
.user-wrapper_app{
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/login/login_bg.png");
  background-size: cover;
  display: flex;
  flex-direction: row;
  z-index: 1;
  justify-content: center;
  //align-items: center;
}

.classA_APP{
  border-bottom: 2px solid #51CBCD;
  font-size: 15px;
  color: #17191A !important;
  font-weight: bold;
  font-family: Source Han Sans CN;
}
.classB_APP{
  font-family: Source Han Sans CN;
  cursor: pointer;
  font-size: 14px;
}

/deep/ .user-wrapper_app .el-input__inner,/deep/ .reset-wrapper_APP .el-input__inner,,/deep/ .experience-wrapper_app .el-input__inner{
  padding: 23px 25px;
  border-radius: 48px;
}
/deep/ .user-wrapper_app .el-input__icon,/deep/ .reset-wrapper_APP .el-input__icon {
  color: #1cdbd8;
}
/deep/ .user-wrapper_app .el-input__prefix {
  top: 3px;
}
/deep/ .user-wrapper_app .el-form-item--small.el-form-item {
  margin-bottom: 10px;
}
/deep/ .user-wrapper_app .flex-a-b-c1 .el-input__inner,,/deep/ .reset-wrapper_APP .flex-a-b-c1 .el-input__inner {
  border: 0;
}
.flex-a-b-c1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #DCDFE6;
  border-radius: 48px;
}
.reset-wrapper_APP {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.experience-wrapper_app {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  //padding: 40px 0;
}











//pc
.login_wrap{
  width: 100%;
  height: 100%;
}
.login-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/login/login_bg.png");
  background-size: cover;
  display: flex;
  flex-direction: row;
  z-index: 1;
  justify-content: center;
  align-items: center;
}
.switch_img {
  position: absolute;
  top: 8px;
  right: 8px;
}
.bg-wrapper:hover {
  box-shadow: 0px 0px 40px rgba(39, 148, 175, 0.1);
  transition: all 0.4s;
}
.bg-wrapper .el-input__inner {
  border: none;
}


/deep/ .user-wrapper .el-input__inner{
  padding: 22px 25px;
}


.opacity0 {
  opacity: 1;
  transition: all 0.2s;
}
.opacity1 {
  opacity: 1;
  transition: all 0.2s;
}
.code-wrapper {
  width: 100%;
  height: 100%;
  padding: 30px 0;
}
.experience-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 40px 0;
}

.reset-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 40px 0;
}
.classA{
  border-bottom: 2px solid #51CBCD;
  font-weight: bold;
}
.classB{
  cursor: pointer;
}
/deep/ .user-wrapper .el-input__icon {
  color: #1cdbd8;
}
.http_info:hover{
  color: #20aee3;
  cursor: pointer;
}
</style>
